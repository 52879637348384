<template>
  <div class="search_filter_wrap">
    <div class="search_item_wrap">
      <div class="search_input">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @keypress.enter.native="setFilterFunc('ni',searchInput)"/>
        <button class="search_btn" @click="setFilterFunc('ni',searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
      <div class="end_filter">
        <!--        <button class="filter_btn two" @click="button>-->
        <el-popover
            popper-class="top_500"
            placement=""
            width="435"
            trigger="click"
            :open-delay="300"
            :offset="0"
            :append-to-body="false"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
          <el-button slot="reference" class="filter_btn two" @click=""></el-button>
          <div class="popup_tail"></div>
          <div class="popup_bg">
            <div class="popup_inner">
              <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
              <div class="inner_middle">
                <div class="sub_title">{{$t('type')}}</div>
                <div class="type_wrap">
                  <el-checkbox-group v-model="filterType" @change="setFilterFunc('pr', filterType)">
                    <el-checkbox :label="1">
                      {{$t('special')}}
                    </el-checkbox>
                    <el-checkbox :label="0">
                      {{$t('normal')}}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="sub_title">{{$t('curator')}}</div>
                <el-checkbox-group v-model="filterCurator" @change="setFilterFunc('gid', filterCurator)">
                  <el-checkbox :label="2" >
                    {{$t('curator')}}
                  </el-checkbox>
                  <el-checkbox :label="1" >
                    {{$t('normal')}}
                  </el-checkbox>
                </el-checkbox-group>
                <div class="sub_title">{{$t('item')}}</div>
                <div class="price_wrap">
                  <el-input v-model="filterItem[0]" @keypress.native.enter="changeFilterItem"
                            class="el_price_input el_input_num no_text" type="number"/>
                  <span class="first_input_step"> ~ </span>
                  <el-input v-model="filterItem[1]" @keypress.native.enter="changeFilterItem"
                            class="el_price_input el_input_num" type="number"/>
                  <span> </span>
                </div>
                <div class="sub_title">{{$t('like')}}</div>
                <div class="price_wrap">
                  <el-input v-model="filterLike[0]" @keypress.native.enter="changeFilterLike()"
                            class="el_price_input el_input_num no_text" type="number"/>
                  <span class="first_input_step"> ~ </span>
                  <el-input v-model="filterLike[1]" @keypress.native.enter="changeFilterLike()"
                            class="el_price_input el_input_num" type="number"/>
                  <span> </span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper_bottom" @click="changeFilterItem()">
            <button class="">{{$t('apply2')}}</button>
          </div>
        </el-popover>
      </div>

    </div>
  </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";

const {filterConfig} = memberUtil
export default {
  name: "MemberFilterInterestCreatorLayout",
  mixins: [],
  components: {
  },
  inject: ['setFilter', 'setSort'],
  provide() {
    return{

    }
  },
  props: {
    filterData: {default: () => {}},
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'i.updated_at',
      searchInput: '',
      filterType: [],
      filterCurator: [],
      filterLike: ["", ""],
      filterItem: ["", ""],

    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
    changeFilterItem() {
      if (this.filterItem[1] !== '' && this.filterItem[0] > this.filterItem[1]) {
        return
      }
      this.setFilterFunc('itc', this.filterItem);
    },
    changeFilterLike() {
      if (this.filterLike[1] !== '' && this.filterLike[0] > this.filterLike[1]) {
        return
      }
      this.setFilterFunc('lic', this.filterLike);
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    openModal(name) {
      this.$modal.show(name);
    },

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
